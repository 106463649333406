<template>
    <zw-sidebar @shown="shown" :title="$t('common.button.import_history')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <zw-table ref="table"
                          tableName="import_history"
                          :items="myProvider"
                          :customSource="getThis()"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          :actions-list="getActions()"
                          :sticky="false"
                          sticky-header="100%"
                >
                    <template #cell(file)="row">
                        <a href="#" @click.prevent="downloadFile(row.item.file)">{{ row.item.file }}</a>
                    </template>
                    <template #cell(actions)="row">
                        <b-button
                            @click="$root.$children[0].openModal('import-history-log-modal', {id:row.item.id})"
                            variant="primary"
                            size="sm"
                        >
                            {{ $t('common.button.open') }}
                        </b-button>
                    </template>
                </zw-table>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'ImportHistory',
    mixins: [commonSave, commonTable],
    data() {
        return {
            loading: true,
            payload: {},
            saveFilters: false,
        }
    },
    computed: {},
    methods: {
        ...mapGetters('Import', ['getHistoryTable']),
        shown() {
            this.loading = false
        },
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Import/fetchHistoryTable', 'getHistoryTable');
        },
        ctxAdditionalParams() {
            return {
                'type':  this.payload.type
            }
        },
        downloadFile(file) {
            this.$root.downloadDocument(file, 'import_files')
        },
        getActions() {
            return []
        },
    },
}
</script>